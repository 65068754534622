import React from "react";
import { Redirect, Route } from "react-router-dom";

const allowedRoutes = [
  "/dashboard/main",
  "/dashboard/leads/all",
  "/dashboard/leads/foodiary",
  "/dashboard/customers/list",
  "/onboarding/legal-informations",
  "/dashboard/customers/token-request",
  "/dashboard/marketing-portal/werbeassets",
  "/dashboard/marketing-portal/print-data",
  "/dashboard/marketing-portal/partners",
  "/dashboard/social-media/instagram",
  "/dashboard/social-media/linkedin",
  "/dashboard/website/overview",
  "/dashboard/website/setup",
  "/dashboard/website/coach-card",
  "/dashboard/website/customer-feedback",
  "/dashboard/support/faq",
  "/dashboard/support/health-insurance-reimbursement",
  "/dashboard/academy/video",
  "/dashboard/academy/white-paper",
  "/dashboard/academy/experts-calls",
  "/dashboard/partnerships/experts",
  "/dashboard/partnerships/education",
  "/dashboard/partnerships/marketing",

  "/onboarding/starter",
  "/onboarding/call-recording",
  "/onboarding/interview-steps",
  "/onboarding/trainer-introduction",
  "/onboarding/foodiary-benefits",
  "/onboarding/registration/gender",
  "/onboarding/registration/goal",
  "/onboarding/registration/anamnese_evaluation",
  "/onboarding/registration/body-details",
  "/onboarding/registration/daily-routine",
  "/onboarding/registration/activity-level",
  "/onboarding/registration/training-days",
  "/onboarding/registration/diet-categories",
  "/onboarding/registration/eating-habits",
  "/onboarding/registration/personal-data",
  "/onboarding/nutrition/creation-video",
  "/onboarding/nutrition/type-update",
  "/onboarding/nutrition/recipies",
  "/onboarding/nutrition/calories",
  "/onboarding/nutrition/macro-nutrients",
  "/onboarding/nutrition/done",
  "/onboarding/healthscore/investigation",
  "/onboarding/healthscore/priorities",
  "/onboarding/healthscore/anamnese-evaluation",
  "/onboarding/healthscore/tips-and-tricks",
  "/onboarding/healthscore/improvements",
  "/onboarding/healthscore/premium-coaching-features",
  "/onboarding/subscriptions",
  "/onboarding/subscriptions/details",
  "/onboarding/foodiary/family",
];

const CustomRoute = ({ component: Component, restricted, path, ...rest }) => {
  const authenticated = localStorage.getItem("trainer_token") !== null;
  const userAuthenticated = localStorage.getItem("token") !== null;

  const loadChatScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://widget.superchat.de/snippet.js?applicationKey=WC3Xmk0Od84BvMppEAQJyejGKw";
    script.async = true;
    script.referrerPolicy = "no-referrer-when-downgrade";
    document.body.appendChild(script);
  };

  const removeChatScript = () => {
    const scripts = document.getElementsByTagName("script");
    console.log("scripts", scripts);
    for (let i = 0; i < scripts.length; i++) {
      if (
        scripts[i].src ===
          "https://widget.superchat.de/snippet.js?applicationKey=WC3Xmk0Od84BvMppEAQJyejGKw" ||
        scripts[i].src === "https://widget.superchat.de/sdk.js"
      ) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }
    }

    const iframe = document.getElementById("superchat-widget");
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
  };

  if ((authenticated || userAuthenticated) && !allowedRoutes.some((route) => window.location.pathname.startsWith(route))) {
    loadChatScript();
  }

  if (!(authenticated || userAuthenticated)) {
    removeChatScript();
  }

  if (
    allowedRoutes.some((route) => window.location.pathname.startsWith(route))
  ) {
    console.log(
      "allowedRoutes.some((route) => window.location.pathname.startsWith(route)",
      allowedRoutes.some((route) => window.location.pathname.startsWith(route))
    );
    console.log("removeChatScript()", window.location.pathname);
    removeChatScript();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !restricted || ((authenticated || userAuthenticated) && restricted) ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <>
            <Redirect to="/login" />
          </>
        )
      }
    />
  );
};

export default CustomRoute;
