import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { getS3ImageUrl } from "configs/utils";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";
import moment from "moment";
import LinkIcon from "assets/images/link.svg";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import PDFIcon from "assets/images/pdf.svg";
import DownloadIcon from "assets/images/downloadBlack.svg";
import ArrowRightAngleIcon from "assets/images/arrowRightAngle.svg";

import * as AuthActions from "redux/auth/auth.actions";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "../../components/clientDetails/clientDetails.styles";
import * as C from "./trainerProfileDetails.styles";
import { MENUS } from "./data";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";
import Pagination from "components/Pagination/Pagination";

const initialDates = {
  startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 26),
  endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 25),
};

const monthOptions = [
  { value: 1, label: "Januar", englishLabel: "January" },
  { value: 2, label: "Februar", englishLabel: "February" },
  { value: 3, label: "März", englishLabel: "March" },
  { value: 4, label: "April", englishLabel: "April" },
  { value: 5, label: "Mai", englishLabel: "May" },
  { value: 6, label: "Juni", englishLabel: "June" },
  { value: 7, label: "Juli", englishLabel: "July" },
  { value: 8, label: "August", englishLabel: "August" },
  { value: 9, label: "September", englishLabel: "September" },
  { value: 10, label: "Oktober", englishLabel: "October" },
  { value: 11, label: "November", englishLabel: "November" },
  { value: 12, label: "Dezember", englishLabel: "December" },
];

const Credits = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const trainerInvoices = useSelector(
    (state) => state.dashboard.trainerInvoices
  );
  const isTrainerInvoicesLoading = useSelector(
    (state) => state.dashboard.isTrainerInvoicesLoading
  );

  const trainerCredits = useSelector((state) => state.dashboard.trainerCredits);
  const isTrainerCreditsLoading = useSelector(
    (state) => state.dashboard.isTrainerCreditsLoading
  );

  const [CurrentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [AllPages, setAllPages] = useState([]);
  const [SliceOne, setSliceOne] = useState([]);
  const [SliceTwo, setSliceTwo] = useState([]);
  const [SlicedPagination, setSlicedPagination] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [years, setYears] = useState([]);
  const [pdfInvoices, setPdfInvoices] = useState([]);
  const [calculatedDates, setCalculatedDates] = useState(initialDates);

  const monthsName = monthOptions.map((o) => o.englishLabel);

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    onPaginationClick(1);
    getTrainerInvoices();
    getTrainerCredits();
    setYears(getNext40Years());

    const { month, year } = getCurrentDateInfo();
    console.log("month", month);
    console.log("year", year);
    setSelectedMonth(month);
    setSelectedYear(year);
  }, []);

  const getCurrentDateInfo = () => {
    const currentDate = new Date();
    const currentMonth = monthsName[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    return {
      month: monthOptions.find((o) => o.englishLabel === currentMonth),
      year: { id: currentYear, value: currentYear, label: currentYear },
    };
  };

  const getTrainerInvoices = () => {
    dispatch(
      DashboardActions.getTrainerInvoicesRequest({
        payload: {
          trainerId: trainerProfileData?.trainerID,
        },
        callback: (res) => {
          console.log("getTrainerInvoicesRequest", res);
          setPdfInvoices(filteredInvoices(res?.data));
        },
      })
    );
  };

  const getTrainerCredits = (
    month = moment().month() + 1,
    year = moment().year()
  ) => {
    dispatch(
      DashboardActions.getTrainerCreditsRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month,
          year,
        },
        callback: (res) => {
          console.log("getTrainerCreditsRequest--------------", res);
        },
      })
    );
  };

  const getNext40Years = () => {
    const startYear = 2023;
    const years = [];

    for (let i = 0; i <= 40; i++) {
      const year = startYear + i;
      years.push({
        id: year,
        value: year,
        label: year,
      });
    }
    console.log("ALl years", years);
    return years;
  };

  const calculateDates = (year, month) => {
    const currentMonthIndex = monthsName.indexOf(month);
    const startMonthIndex = currentMonthIndex - 1;

    const startDate = new Date(year, startMonthIndex, 26);
    const endDate = new Date(year, currentMonthIndex, 25);

    // startDate.setHours(24, 0, 0, 0);
    // endDate.setHours(24, 0, 0, 0);

    console.log("startdate", startDate);
    console.log("endDate", endDate);
    setCalculatedDates({
      startDate,
      endDate,
    });
  };

  const onPaginationClick = (pageIdx) => {
    let page = pageIdx;
    setCurrentPage(page);

    const total = Math.ceil(invoiceData?.total / invoiceData?.per_page);
    let allPages = [];

    for (let index = 1; index <= total; index++) {
      allPages.push(index);
    }

    setAllPages(allPages);

    let slicedPagination = [];
    let sliceOne = [...SliceOne];
    let sliceTwo = [...SliceTwo];

    sliceOne = allPages.slice(page - 1, page + 5);
    setSliceOne(sliceOne);
    sliceTwo = allPages.slice(-1);
    setSliceTwo(sliceTwo);

    // if (sliceOne.includes(total) && sliceOne.length < 6) {
    //   slicedPagination = [...sliceOne];
    //   setSlicedPagination(slicedPagination);
    //   return;
    // }

    if (sliceOne.includes(total)) {
      if (page === total) {
        sliceOne = allPages.slice(0, 5);
        setSliceOne(sliceOne);
        slicedPagination = [...sliceOne, "...", total];
      }

      if (page < total) {
        sliceOne = allPages.slice(page - 1, page + 5);
        setSliceOne(sliceOne);
        slicedPagination = [1, ...sliceOne];
      }
    } else {
      if (sliceOne.length < 6) {
        slicedPagination = [...sliceOne, ...sliceTwo];
      } else {
        slicedPagination = [...sliceOne, "...", ...sliceTwo];
      }
    }
    // console.log("sliceOne, ", sliceOne);
    // console.log("sliceTwo, ", sliceTwo);
    // console.log("page", page);
    // console.log("slicedPagination", slicedPagination);
    setSlicedPagination([...new Set(slicedPagination)]);
  };

  const invoiceData = useSelector((state) => state.auth.invoiceData);
  const [paidInvoiceTotal, setPaidInvoiceTotal] = useState(null);

  useEffect(() => {
    dispatch(
      AuthActions.getPaidInvoicesByTrainerRequest({
        formData: {
          trainerID: trainerProfileData.trainerID,
          startDate: calculatedDates.startDate,
          endDate: calculatedDates.endDate,
        },
        page: 1,
        callback: (responseData) => {
          setPaidInvoiceTotal(responseData?.data?.total_amount_transferred);
        },
      })
    );
  }, [dispatch, calculatedDates.startDate, calculatedDates.endDate]);

  useEffect(() => {
    setShowLoader(true);
    dispatch(
      AuthActions.getInvoicesByTrainerRequest({
        formData: {
          trainerID: trainerProfileData.trainerID,
          startDate: calculatedDates.startDate,
          endDate: calculatedDates.endDate,
          showPaidInvoice: isChecked,
        },
        page: 1,
        callback: (resOne) => {
          setShowLoader(false);
        },
      })
    );
  }, [calculatedDates.startDate, calculatedDates.endDate, isChecked]);

  const formatDateString = (date) => {
    return date ? format(new Date(date), "dd.MM.yyyy") : "";
  };

  const handleYearChange = (selectedOption) => {
    console.log("handleYearChange", selectedOption);
    setPdfInvoices(
      filteredInvoices(trainerInvoices?.data, selectedMonth, selectedOption)
    );
    setSelectedYear(selectedOption);
    calculateDates(selectedOption?.id, selectedMonth?.englishLabel);

    getTrainerCredits(selectedMonth?.value, selectedOption?.value);
  };

  const handleMonthChange = (selectedOption) => {
    console.log("handleMonthChange", selectedOption);
    setPdfInvoices(filteredInvoices(trainerInvoices?.data, selectedOption));
    setSelectedMonth(selectedOption);
    calculateDates(selectedYear?.id, selectedOption?.englishLabel);

    getTrainerCredits(selectedOption?.value, selectedYear?.value);
  };

  const filteredInvoices = (
    invoices,
    month = selectedMonth,
    year = selectedYear
  ) => {
    const filterInvoices = invoices.filter((invoice) => {
      const monthMatch = +invoice.month === month.value;
      const yearMatch = +invoice.year === year.value;

      return monthMatch && yearMatch;
    });

    console.log("filterInvoices", filterInvoices);

    return filterInvoices;
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleDetailsClick = (invoice) => {
    history.push(`/dashboard/trainer-credits/details?invoiceId=${invoice.id}`);
    // window.location.href = `/dashboard/transfer?invoiceId=${invoice.id}`;
    // dashboard/trainer-credits/details
  };

  const onInvoice = (invoice, property) => {
    window.open(invoice[property], "_blank");
    // window.open(invoice?.file_url, "_blank");
  };

  const onDowloadAll = () => {
    const pdfArray = trainerCredits?.data;
    // const pdfArray = [
    // "https://file-examples.com/storage/fe519944ff66ba53b99c446/2017/10/file-sample_150kB.pdf",
    // "https://file-examples.com/wp-content/storage/2017/10/file-example_PDF_500_kB.pdf",
    // "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/trainer_credits_transaction/FCI1/Transaktion_FCI1_2024_06_tr_3PVDX0Il7sOTHWhg09BjNiRP.pdf",
    // "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/trainer_credits_transaction/FCI1/Transaktion_FCI1_2024_06_tr_3PQ7qRIl7sOTHWhg0WtnZ9fG.pdf",
    // "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/trainer_credits_transaction/Transaktion_FCI1_2024_06_tr_3PSzy5Il7sOTHWhg1yW64Mm7.pdf",
    // "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/trainer_credits_transaction/FCI1/Transaktion_FCI1_2024_06_tr_3PT9joIl7sOTHWhg2pU9Yf43.pdf",
    // ];

    Promise.all(
      pdfArray.map((pdfUrl, index) => {
        const filename = `transaction_${index + 1}.pdf`; // Create a unique filename
        return downloadPDF(pdfUrl, filename);
      })
    );
  };

  const downloadPDF = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Download error:", error));
  };

  const renderStatusBadge = (status) => {
    switch (status) {
      case "Completed":
        return (
          <div className="status-badge text-center green">Erfolgreich</div>
        );

      case "Incomplete":
        return (
          <div className="status-badge text-center orange-yellow">
            Unvollständig
          </div>
        );

      case "Canceled":
        return <div className="status-badge text-center red">Storniert</div>;

      case "Pending":
        return <div className="status-badge text-center grey">Ausstehend</div>;

      case "Refunded":
        return (
          <div className="status-badge text-center red">Zurückerstattet</div>
        );

      case "Processing":
        return (
          <div className="status-badge text-center orange-yellow">
            Wird bearbeitet
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          userProfileInfo={{
            profilePic: getS3ImageUrl(trainerProfileData.profilePic),
            franchiseId: trainerProfileData?.FranchiseID,
            stripeId: trainerProfileData?.["Stripe Connect ID"] || "",
            prename: trainerProfileData.prename,
            surname: trainerProfileData.surname,
            connected_user_account_id:
              trainerProfileData?.connected_user_account_id,
          }}
          onPasswordLink={() => setPasswordResetModal(true)}
          idsSection
        ></InnerSideNav>
      </div>

      <PasswordResetModal
        show={passwordResetModal}
        setShow={setPasswordResetModal}
      />
      <div className="second-sidebar-content">
        <C.TrainerProfileDetailsOverallContainer>
          <S.ContainerBox>
            {/* <S.ContainerBoxWithPadding> */}
            <S.Header>
              <div>
                <S.Heading>Gutschriften</S.Heading>
                <S.SubHeading>
                  Hier findest du die monatlichen Gutschriften für deine
                  Steuerunterlagen.
                </S.SubHeading>
              </div>
              <div className="d-flex gap-3">
                <div>
                  {/* <Dropdown
                    options={months}
                    width=""
                    id="month-dropdown"
                    onChange={handleMonthChange}
                    value={selectedMonth}
                    name="month"
                    margin="10px"
                    style={{
                      margin: "0 0 1rem 1rem ",
                      fontSize: "14px",
                      border: "1px solid #F8F8F8",
                      boxShadow: "0px 1px 2px #1018280D;",
                      background: "#ffffff",
                      borderRadius: "10px",
                    }}
                  /> */}
                  <Select
                    options={monthOptions}
                    className="faq-select"
                    classNamePrefix="react-select"
                    onChange={handleMonthChange}
                    value={selectedMonth}
                    placeholder="Month"
                    isSearchable={false}
                  />
                </div>
                <div>
                  {/* <Dropdown
                    options={years}
                    width=""
                    id="year-dropdown"
                    onChange={handleYearChange}
                    value={selectedYear}
                    name="year"
                    margin="10px"
                    style={{
                      margin: "0 0 1rem 1rem ",
                      fontSize: "14px",
                      border: "1px solid #F8F8F8",
                      boxShadow: "0px 1px 2px #1018280D;",
                      background: "#ffffff",
                      borderRadius: "10px",
                    }}
                  /> */}
                  <Select
                    options={years}
                    className="faq-select"
                    classNamePrefix="react-select"
                    onChange={handleYearChange}
                    value={selectedYear}
                    placeholder="Year"
                    isSearchable={false}
                  />
                </div>
              </div>
            </S.Header>
            {/* </S.ContainerBoxWithPadding> */}

            <S.Divider></S.Divider>
            {showLoader ? (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            ) : (
              <S.PaddingBox>
                <S.CardsWrap>
                  <div className="credit-card">
                    <div className="header">
                      <h6>Auszahlungsbetrag</h6>
                      <div className="date-range">
                        {formatDateString(calculatedDates.startDate)} -{" "}
                        {formatDateString(calculatedDates.endDate)}
                      </div>
                    </div>
                    <div className="body">
                      <span className="income-title">Total</span>

                      <div className="income">
                        <h4>{paidInvoiceTotal || 0}€</h4>
                        <div className="income-status">
                          <span>Anstehende Auszahlung</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="credit-card">
                    <div className="header">
                      <h6>Downloads</h6>
                      <div className="date-range">2 Dokumente</div>
                    </div>
                    <div className="body">
                      {pdfInvoices.length === 0 && (
                        <div className="download-row">
                          <div className="left">
                            <img className="pdf-icon" src={PDFIcon} alt="" />
                            <span>Gutschrifts-Rechnung herunterladen</span>
                          </div>
                        </div>
                      )}
                      {pdfInvoices?.map((invoice) => (
                        <>
                          <div className="download-row" key={invoice?.month}>
                            <div className="left">
                              <img className="pdf-icon" src={PDFIcon} alt="" />
                              <span>Gutschrifts-Rechnung herunterladen</span>
                            </div>

                            <img
                              onClick={() => onInvoice(invoice, "file_url")}
                              className="download-icon cursor-pointer"
                              src={DownloadIcon}
                              alt=""
                            />
                          </div>
                        </>
                      ))}

                      {/*  {trainerCredits &&
                        trainerCredits?.data?.map((link) => ( */}
                      {trainerCredits && trainerCredits?.data?.length > 0 && (
                        <>
                          <div className="download-row">
                            <div className="left">
                              <img className="pdf-icon" src={PDFIcon} alt="" />
                              <span>Alle Transaktionsbelege herunterladen</span>
                            </div>

                            <img
                              onClick={() => onDowloadAll()}
                              className="download-icon cursor-pointer"
                              src={DownloadIcon}
                              alt=""
                            />
                          </div>
                        </>
                      )}

                      {(!trainerCredits ||
                        trainerCredits?.data?.length === 0) && (
                        <>
                          <div className="download-row">
                            <div className="left">
                              <img className="pdf-icon" src={PDFIcon} alt="" />
                              <span>Alle Transaktionsbelege herunterladen</span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </S.CardsWrap>
                <br />
                <div className="d-flex justify-content-end custom_checkbox">
                  <S.SelfMadeCheckBoxRow>
                    <label className="form-control" for="profile">
                      <input
                        id="profile"
                        type="checkbox"
                        name="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <span>Bezahlte Rechnung anzeigen</span>
                    </label>
                  </S.SelfMadeCheckBoxRow>
                </div>
                <br />
                <S.TableWrapper>
                  <table>
                    <thead>
                      <tr>
                        <th>Kunde</th>
                        <th>Status</th>
                        <th>Produkt</th>
                        <th>Transaktionsbeleg</th>
                        <th>Erstellt</th>
                        <th>Geldeingang</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData?.data?.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>
                            <p>{invoice.customer_name}</p>
                          </td>

                          <td>{renderStatusBadge(invoice.payment_status)}</td>

                          <td>
                            <p>{invoice.product_name}</p>
                          </td>

                          <td>
                            {invoice && invoice.transaction_pdf_url ? (
                              <div className="file-item">
                                <img src={PDFIcon} alt="" />
                                <a
                                  href={invoice.transaction_pdf_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {invoice.transfers_id.substring(0, 8)}
                                </a>
                              </div>
                            ) : (
                              <div className="file-item">
                                <span>noch nicht verfügbar</span>
                              </div>
                            )}
                          </td>

                          <td className="text-center">
                            <p>
                              {invoice.invoice_created_at
                                ? invoice.invoice_created_at
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                : "-"}
                            </p>
                          </td>
                          <td className="text-center">
                            <p>
                              {invoice.invoice_money_arrived_at
                                ? invoice.invoice_money_arrived_at
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                : "-"}
                            </p>
                          </td>
                          <td>
                            <button
                              className="btn-outline sm cursor-pointer"
                              onClick={() => handleDetailsClick(invoice)}
                            >
                              Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </S.TableWrapper>
                <br />
                <Pagination
                  currentPage={CurrentPage}
                  recordsPerPage={10}
                  totalRecords={invoiceData?.total}
                  onPageChange={(pageNo) => {
                    setPageNumber(pageNo);
                    onPaginationClick(pageNo);
                    dispatch(
                      AuthActions.getInvoicesByTrainerRequest({
                        formData: {
                          trainerID: trainerProfileData.trainerID,
                          startDate: calculatedDates.startDate,
                          endDate: calculatedDates.endDate,
                          showPaidInvoice: isChecked,
                        },
                        page: pageNo,
                      })
                    );
                  }}
                ></Pagination>
              </S.PaddingBox>
            )}
          </S.ContainerBox>
        </C.TrainerProfileDetailsOverallContainer>
      </div>
    </>
  );
};

export default Credits;
