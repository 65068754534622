import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import * as Utils from "configs/utils";

// Routes
import * as routes from "routes/routes";
import CustomRoute from "routes/customRoute";

// Pages
import TrainerSignup from "pages/TrainerSignup/TrainerSignup";
import Login from "pages/login/Login";
import NewLogin from "pages/NewLogin/NewLogin";
import Dashboard from "pages/dashboard/Dashboard";
import Information from "pages/information/Information";
import WhatAwaitsYou from "pages/whatAwaitsYou/WhatAwaitsYou";
import Registration from "pages/registration/Registration";
import NutritionCreation from "pages/nutritionCreation/NutritionCreation";
import NutritionForm from "pages/nurtritionForm/NutritionForm";
import FormAdvantages from "pages/formAdvantages.js/FormAdvantages";
import NutritionRecipes from "pages/nutritionRecipes/NutritionRecipes";
import NutritionCalories from "pages/nutritionCalories/NutritionCalories";
import MacroNutrients from "pages/macroNutritients/MacroNutrients";
import NutritionDone from "pages/nutritionDone/NutritionDone";
import HealthScoreInvestigation from "pages/healthScoreInvestigation/HealthScoreInvestigation";
import HealthScoreImprovement from "pages/healthScoreImprovement/HealthScoreImprovement";
import Overview from "pages/overview/Overview";
import OverviewBasic from "pages/overviewBasic/OverviewBasic";
import OrderDetails from "pages/orderDetails/OrderDetails";
import FoodiaryFamily from "pages/fooddiaryFamily/FoodiaryFamily";
import PremiumCoaching from "pages/premiumCoaching/PremiumCoaching";
import PremiumCoachingSuccess from "pages/premiumCoachingSuccess/PremiumCoachingSuccess";
import HealthScoreTips from "pages/healthScoreTips/HealthScoreTips";
import HealthScorePriority from "pages/healthScorePriority/HealthScorePriority";
import HealthScoreEvaluation from "pages/healthScoreEvaluation/HealthScoreEvaluation";
import HealthScoreDailyBudget from "pages/healthScoreDailyBudget/HealthScoreDailyBudget";
import Supplements from "pages/supplements/Supplements";
import ChangePassword from "pages/chanagePassword/ChangePassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import SetPassword from "pages/SetPassword/SetPassword";
import CourseChangePassword from "pages/CourseChangePassword/CourseChangePassword";
import SendChangePasswordEmail from "pages/SendChangePasswordEmail/SendChangePasswordEmail";

import Subscription from "pages/Subscription/Subscription";
import SubscriptionDetails from "pages/subscriptionDetails/SubscriptionDetails";
import SubscriptionPro from "pages/subscriptionPro/subscriptionPro";
import SubscriptionBasic from "pages/subscriptionBasic/subscriptionBasic";
import ContactPersons from "pages/contactPersons/ContactPersons";
import Administration from "pages/Administration/Administration";
import MainDashoard from "pages/MainDashboard/MainDashoard";
import Influencers from "pages/influencer/Influencer";
import InfluencersDetails from "pages/influencer/InfluencerDetails";
import WeeklyCallsForSugesstions from "pages/weeklyCallsForSugesstions/weeklyCallsForSugesstions";
import EvaluationOfOverallCondition from "pages/EvaluationOfOverallCondition/EvaluationOfOverallCondition";
import EvaluationOfOverallConditionForm from "pages/EvaluationOfOverallConditionForm/EvaluationOfOverallConditionForm";
import Academy from "pages/AcademyWrapper/Academy/Academy";
import AcademyDetails from "pages/AcademyWrapper/AcademyDetails/AcademyDetails";
import NewDashBoard from "pages/NewDasboard/DashBoard";
import NewDasboardLayout from "pages/NewDasboard/NewDashboardLayout";
import Starter from "pages/OnBoardingPartOne/Starter";
import LegalInformation from "pages/OnBoardingPartOne/LegalInformation";
import CallRecording from "pages/OnBoardingPartOne/CallRecording";
import InitialInterviewSteps from "pages/OnBoardingPartOne/InitialInterviewSteps";
import TrainerIntroduction from "pages/OnBoardingPartOne/TrainerIntroduction";
import FoodiaryBenefits from "pages/OnBoardingPartOne/FoodiaryBenefits";
import Gender from "pages/OnBoardingPartOne/Registration/Gender";
import Goal from "pages/OnBoardingPartOne/Registration/Goal";
import AnamneseEvaluation from "pages/OnBoardingPartOne/Registration/AnamneseEvaluation";
import BodyDetails from "pages/OnBoardingPartOne/Registration/BodyDetails";
import DailyRoutine from "pages/OnBoardingPartOne/Registration/DailyRoutine";
import ActivityLevel from "pages/OnBoardingPartOne/Registration/ActivityLevel";
import TrainingDays from "pages/OnBoardingPartOne/Registration/TrainingDays";
import DietCategories from "pages/OnBoardingPartOne/Registration/DietCategories";
import EatingHabbits from "pages/OnBoardingPartOne/Registration/EatingHabbits/EatingHabbitsOne";
import EatingHabbitsOne from "pages/OnBoardingPartOne/Registration/EatingHabbits/EatingHabbitsOne";
import PersonalData from "pages/OnBoardingPartOne/Registration/PersonalData";
import NutritionCreationPreload from "pages/OnBoardingPartTwo/Registration/NutritionCreationPreload";
import NutritionTypeUpdate from "pages/OnBoardingPartTwo/Registration/NutritionTypeUpdate";
import NutritionRecipies from "pages/OnBoardingPartTwo/Registration/NutritionRecipes";
import NutritionCaloriesNew from "pages/OnBoardingPartTwo/Registration/NutritionCalories";
import MacroNutrientsNew from "pages/OnBoardingPartTwo/Registration/MacroNutrients";
import NutritionCompleted from "pages/OnBoardingPartTwo/Registration/NutritionCompleted";
import HealthScoreDetermination from "pages/OnBoardingPartThree/Registration/HealthScoreDetermination";
import HealthScorePriorities from "pages/OnBoardingPartThree/Registration/HealthScorePriorities";
import AllAnamneseEvaluation from "pages/OnBoardingPartThree/Registration/AllAnamneseEvaluation";
import HealthScoreTipsAndTricks from "pages/OnBoardingPartThree/Registration/HealthScoreTipsAndTricks";
import HealthScoreImprovements from "pages/OnBoardingPartThree/Registration/HealthScoreImprovements";
import PremiumCoachingFeatures from "pages/OnBoardingPartThree/Registration/PremiumCoachingFeatures";
import UserSubscriptions from "pages/OnBoardingPartThree/Registration/UserSubscriptions";
import UserSubscriptionsDetails from "pages/OnBoardingPartThree/Registration/UserSubscriptionsDetails";
import FoodiaryFamilyJoined from "pages/OnBoardingPartThree/Registration/FoodiaryFamilyJoined";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import mobileRestrictionBg from "assets/images/mobile-restriction-bg.svg";

const hotjarRoutes = [
  "/onboarding/legal-informations",
  "/onboarding/starter",
  "/onboarding/call-recording",
  "/onboarding/interview-steps",
  "/onboarding/trainer-introduction",
  "/onboarding/foodiary-benefits",
  "/onboarding/registration/gender",
  "/onboarding/registration/goal",
  "/onboarding/registration/anamnese_evaluation",
  "/onboarding/registration/body-details",
  "/onboarding/registration/daily-routine",
  "/onboarding/registration/activity-level",
  "/onboarding/registration/training-days",
  "/onboarding/registration/diet-categories",
  "/onboarding/registration/eating-habits/:id",
  "/onboarding/registration/personal-data",
  "/onboarding/nutrition/creation-video",
  "/onboarding/nutrition/type-update",
  "/onboarding/nutrition/recipies",
  "/onboarding/nutrition/calories",
  "/onboarding/nutrition/macro-nutrients",
  "/onboarding/nutrition/done",
  "/onboarding/healthscore/investigation",
  "/onboarding/healthscore/priorities",
  "/onboarding/healthscore/anamnese-evaluation",
  "/onboarding/healthscore/tips-and-tricks",
  "/onboarding/healthscore/improvements",
  "/onboarding/healthscore/premium-coaching-features",
  "/onboarding/subscriptions",
  "/onboarding/subscriptions/details",
  "/onboarding/foodiary/family",
];

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
};

const App = () => {
  useScrollToTop();

  // const location = useLocation();
  const [isHotjarInitialized, setHotjarInitialized] = useState(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const excludeViewRestrictionRoutes = [
    "/course-changepassword",
    "/changePassword",
    "/reset-password",
    "/foodiary-aktivieren",
  ];

  const shouldRestrictView =
    windowSize <= 430 &&
    !excludeViewRestrictionRoutes.some(
      (route) => route === window.location.pathname
    );

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // if (hotjarRoutes.includes(window.location.pathname)) {
    //   if (typeof window?.hj !== "undefined") {
    //     Utils.startRecording();
    //   } else {
    //     console.error("Hotjar is not available yet.");
    //   }
    // } else {
    //   Utils.stopRecording();
    // }

    // console.log(
    //   "%cRoute Change------------------->",
    //   "font-size: 16px; font-weight: bold", window.location.pathname
    // );
  }, [window.location.pathname]);

  if (shouldRestrictView) {
    return (
      <div className="screen-size-warning">
        <div className="screen-size-content">
          <div className="screen-size-logo">
            <img src={FoodiaryLogo} alt="logo" />
          </div>
          <div className="screen-size-bg">
            <img src={mobileRestrictionBg} />
          </div>
          <p>
            Aus Gründen der Qualitätssicherung kann das Dashboard ausschließlich
            auf dem Desktop Pc, Laptop oder Tablet geöffnet werden.
          </p>
        </div>
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#73BCAE",
          colorLink: "#73BCAE",
          colorTextHeading: "#73BCAE",
          colorTextLabel: "#73BCAE",
          colorTextSecondary: "#73BCAE",
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <Router>
          <Switch>
            {/* <CustomRoute
            path={routes.NEW_DASHBOARD_LAYOUT}
            exact
            component={NewDasboardLayout}
          /> */}

            <CustomRoute
              path={routes.LEGAL_INFORMATION}
              exact
              component={LegalInformation}
            />

            <CustomRoute
              path={routes.ONBOARDING_STARTER}
              exact
              component={Starter}
              restricted
            />

            <CustomRoute
              path={routes.CALL_RECORDING}
              exact
              component={CallRecording}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_INTERVIEW_STEPS}
              exact
              component={InitialInterviewSteps}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_TRAINER_INTRODUCTION}
              exact
              component={TrainerIntroduction}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_FOODIARY_BENEFITS}
              exact
              component={FoodiaryBenefits}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_GENDER}
              exact
              component={Gender}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_GOAL}
              exact
              component={Goal}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_ANAMNESE_EVALUATION}
              exact
              component={AnamneseEvaluation}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_BODY_DETAILS}
              exact
              component={BodyDetails}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_DAILY_ROUTINE}
              exact
              component={DailyRoutine}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_ACTIVITY_LEVEL}
              exact
              component={ActivityLevel}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_TRAINING_DAYS}
              exact
              component={TrainingDays}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_DIET_CATEGORIES}
              exact
              component={DietCategories}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_EATING_HABBITS}
              exact
              component={EatingHabbitsOne}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_REGISTRATION_PERSONAL_DATA}
              exact
              component={PersonalData}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_CREATION}
              exact
              component={NutritionCreationPreload}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_TYPE_UPDATE}
              exact
              component={NutritionTypeUpdate}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_RECIPES}
              exact
              component={NutritionRecipies}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_CALORIES}
              exact
              component={NutritionCaloriesNew}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_MACRO_NUTRIENTS}
              exact
              component={MacroNutrientsNew}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_NUTRITION_DONE}
              exact
              component={NutritionCompleted}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_INVESTIGATION}
              exact
              component={HealthScoreDetermination}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_PRIORITIES}
              exact
              component={HealthScorePriorities}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_ANAMNESE_EVALUATION}
              exact
              component={AllAnamneseEvaluation}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_TIPS_AND_TRICKS}
              exact
              component={HealthScoreTipsAndTricks}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_IMPROVEMENTS}
              exact
              component={HealthScoreImprovements}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_HEALTHSOCRE_PREMIUM_COACHING_FEATURES}
              exact
              component={PremiumCoachingFeatures}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_SUBSCRIPTIONS}
              exact
              component={UserSubscriptions}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_SUBSCRIPTIONS_DETAILS}
              exact
              component={UserSubscriptionsDetails}
              restricted
            />

            <CustomRoute
              path={routes.ONBOARDING_FOODIARY_FAMILY}
              exact
              component={FoodiaryFamilyJoined}
              restricted
            />

            {/* ----------------------------------- */}
            <CustomRoute
              path={routes.TRAINER_SIGNUP}
              exact
              component={TrainerSignup}
            />
            {/* <CustomRoute path={routes.LOGIN} exact component={Login} /> */}
            <CustomRoute path={routes.LOGIN} exact component={NewLogin} />
            <CustomRoute
              path={routes.CHANGE_PASSWORD}
              exact
              component={ChangePassword}
            />
            <CustomRoute
              path={routes.RESET_PASSWORD}
              exact
              component={ResetPassword}
            />

            <CustomRoute
              path={routes.SET_PASSWORD}
              exact
              component={SetPassword}
            />

            <CustomRoute
              path={routes.COURSE_CHANGE_PASSWORD}
              exact
              component={CourseChangePassword}
            />

            <CustomRoute
              path={routes.SEND_EMAIL}
              exact
              component={SendChangePasswordEmail}
            />

            <CustomRoute
              restricted
              path={routes.ROOT}
              exact
              component={Information}
            />

            <CustomRoute
              restricted
              path={routes.DASHBOARD}
              exact
              component={Dashboard}
            />
            <CustomRoute
              restricted
              path={routes.INFLUENCERS}
              exact
              component={Influencers}
            />
            <CustomRoute
              restricted
              path={routes.INFLUENCERS_DETAILS}
              exact
              component={InfluencersDetails}
            />
            {/* <CustomRoute
            restricted
            path={routes.MAIN_DASHBOARD}
            exact
            component={MainDashoard}
          /> */}
            <CustomRoute
              restricted
              path={routes.WHAT_AWAITS_YOU}
              exact
              component={WhatAwaitsYou}
            />
            <CustomRoute
              restricted
              path={routes.REGISTRATION}
              exact
              component={Registration}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_CREATION}
              exact
              component={NutritionCreation}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_FORM}
              exact
              component={NutritionForm}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_FORM_ADVANTAGES}
              exact
              component={FormAdvantages}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_RECIPES}
              exact
              component={NutritionRecipes}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_CALORIES}
              exact
              component={NutritionCalories}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_MACRO_NUTRIENTS}
              exact
              component={MacroNutrients}
            />
            <CustomRoute
              restricted
              path={routes.NUTRITION_DONE}
              exact
              component={NutritionDone}
            />
            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_INVESTIGATION}
              exact
              component={HealthScoreInvestigation}
            />
            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_IMPROVEMENT}
              exact
              component={HealthScoreImprovement}
            />
            <CustomRoute
              restricted
              path={routes.OVERVIEW}
              exact
              component={Overview}
            />
            <CustomRoute
              restricted
              path={routes.OVERVIEW_BASIC}
              exact
              component={OverviewBasic}
            />
            <CustomRoute
              restricted
              path={routes.ORDER_DETAILS}
              exact
              component={OrderDetails}
            />
            <CustomRoute
              restricted
              path={routes.FOODIARY_FAMILY}
              exact
              component={FoodiaryFamily}
            />
            <CustomRoute
              restricted
              path={routes.PREMIUM_COACHING}
              exact
              component={PremiumCoaching}
            />
            <CustomRoute
              restricted
              path={routes.PREMIUM_COACHING_SUCCESS}
              exact
              component={PremiumCoachingSuccess}
            />
            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_TIPS}
              exact
              component={HealthScoreTips}
            />
            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_PRIORITY}
              exact
              component={HealthScorePriority}
            />
            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_EVALUATION}
              exact
              component={HealthScoreEvaluation}
            />

            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_ANAMNESE_EVALUATION}
              exact
              component={EvaluationOfOverallCondition}
            />

            <CustomRoute
              restricted
              path={routes.HEALTH_SCORE_DAILY_BUDGET}
              exact
              component={HealthScoreDailyBudget}
            />
            <CustomRoute
              restricted
              path={routes.SUPPLEMENTS}
              exact
              component={Supplements}
            />
            <CustomRoute
              restricted
              path={routes.SUBSCRIPTIONS}
              exact
              component={Subscription}
            />
            <CustomRoute
              restricted
              path={routes.SUBSCRIPTIONS_DETAILS}
              exact
              component={SubscriptionDetails}
            />
            <CustomRoute
              restricted
              path={routes.SUBSCRIPTIONS_PRO}
              exact
              component={SubscriptionPro}
            />
            <CustomRoute
              restricted
              path={routes.SUBSCRIPTIONS_BASIC}
              exact
              component={SubscriptionBasic}
            />
            <CustomRoute
              restricted
              path={routes.CONTACT_PERSONS}
              exact
              component={ContactPersons}
            />
            <CustomRoute
              restricted
              path={routes.ADMINISTRATION}
              exact
              component={Administration}
            />
            <CustomRoute
              restricted
              path={routes.OVERALL_EVALUATION}
              exact
              component={EvaluationOfOverallCondition}
            />
            <CustomRoute
              restricted
              path={routes.OVERALL_EVALUATION_FORM}
              exact
              component={EvaluationOfOverallConditionForm}
            />
            <CustomRoute
              restricted
              path={routes.ACADEMY}
              exact
              component={Academy}
            />
            <CustomRoute
              restricted
              path={routes.ACADEMY_DETAILS}
              exact
              component={AcademyDetails}
            />
            {/* <CustomRoute
            restricted
            path={routes.WEEKLY_CALLS_FOR_SUGGESTIONS}
            exact
            component={WeeklyCallsForSugesstions}
          /> */}
            {/* <Route path={routes.SUBSCRIPTIONS_PRO}
            exact render={SubscriptionPro} />
          <Route path={routes.SUBSCRIPTIONS_BASIC}
            exact render={SubscriptionBasic} /> */}
            <CustomRoute
              path={routes.NEW_DASHBOARD}
              component={NewDasboardLayout}
            />
            <Route path="/" render={() => <Redirect to="/dashboard/main" />} />

            <Route path="*" render={() => "Page Not Found"} />
          </Switch>
        </Router>
      </div>
    </ConfigProvider>
  );
};

export default App;
